import React, { useState } from "react";
import axios from "axios";
import AuthContext from "../contexts/AuthContext";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const regereAuthProvider = {
  isAuthenticated: false,
  signin(callback) {
    regereAuthProvider.isAuthenticated = true;

    setTimeout(callback, 1); // fake async
  },
  signout(callback) {
    regereAuthProvider.isAuthenticated = false;
    setTimeout(callback, 1);
  }
};

function AuthProvider({ children }) {
  let [user, setUser] = useState(null);

  let signin = (newUser, callback) => {
    return regereAuthProvider.signin(async () => {
      try {
        const response = await axios({
          method: 'post',
          url: `${API_BASE_URL}/cockpitUsers/login`,
          data: {
            email: newUser.username,
            password: newUser.password
          }
        });
        if (response.status && response.data.uid) {
          // Set access token and uid in cookies
          document.cookie = `uid=${response.data.uid};`;
          document.cookie = `access_token=${response.data.access};`
          document.cookie = `role=${response.data.roles[0]}`
          setUser({
            uid: response.data.uid,
            accessToken: response.data.access
          });
          callback(true);
          return;
        }
        callback(false);

      }
      catch (e) {
        console.log(e);
        callback(false);
      }
    });
  };

  let signout = (callback) => {
    return regereAuthProvider.signout(() => {
      setUser(null);
      callback();
    });
  };

  let value = { user, signin, signout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}




export default AuthProvider;