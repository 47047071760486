import {
  API_BASE_URL,
  BULK_UPLOAD_ROUTE_PATH
} from "../../utils/constants";

export const JOBS_API_URL = `${API_BASE_URL}/jobs`;
export const CURRENT_ASSIGNEE_JOBS_API_URL = `${API_BASE_URL}/jobs/currentAssignee`;

export const items = [
  { name: 'Bulk Upload', href: BULK_UPLOAD_ROUTE_PATH },
]
export const ALLOWED_PRIORITY = ['Normal', 'High'];
export const DEFAULT_CLIENTS = ["MOS", "PMR", "MRS", "WBD", "SMC", "RR", "MLE", "NCN", "JP", "H&C", "CF", "FPA", "EHR", "Tosk", "Uraine", "Netmark"];
export const DEFAULT_JOB_TYPES = ["Initial", "Additional"];
export const DEFAULT_PROJECT = ["Not Applicable","Clark & Howell","Dr. Hupp", "Dr. Strudwick", "Gabe Merill", "Gregory Whitman", "JRK", "Kamyar Kamjoo", "Kim Price", "Kuhn Cogn", "Luccia & Evans", "McClure", "MLG Kurt", "Purdy Jolly", "Ryan Womac", "Shook & Stone", "3M Ear Plug", "Atrium HM", "Baby Formula", "CPAP", "Hernia Mesh - 9", "Hernia Mesh - 6", "Hernia Mesh - 12", "Paraquat", "Railroad", "Roundup", "Med Mal"];
export const DEFAULT_WORK_TYPES = ["Chronology Summary", "Spreadsheet", "Narrative Summary", "Hospital Sorting", "Nursing Sorting", "Nursing Hospital Sorting"];

export const PROJECT_FILTERS = () => {
  return DEFAULT_PROJECT.map(client => {
    return{ 
      value: client, 
      label: client, 
      checked: false 
    } 
  })
}

export const ALLOWED_PRIORITY_FILTERS = () => {
  return ALLOWED_PRIORITY.map(client => {
    return{ 
      value: client, 
      label: client, 
      checked: false 
    } 
  })
}

export const WORK_TYPES_FILTERS = () => {
  return DEFAULT_WORK_TYPES.map(client => {
    return{ 
      value: client, 
      label: client, 
      checked: false 
    } 
  })
}

export const JOB_TYPES_FILTERS = () => {
  return DEFAULT_JOB_TYPES.map(client => {
    return{ 
      value: client, 
      label: client, 
      checked: false 
    } 
  })
}

export const CLIENT_FILTERS = () => {
  return DEFAULT_CLIENTS.map(client => {
    return{ 
      value: client, 
      label: client, 
      checked: false 
    } 
  })
}
