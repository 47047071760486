export default function Input(props) {
  return (
    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 sm:pt-5">
      <label
        htmlFor={props.name}
        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
      >
        {props.title}
      </label>

      <div className="mt-1 sm:mt-0 sm:col-span-2">
        <input
          type={props.type || "text"}
          name={props.name}
          id={props.name}
          autoComplete="family-name"
          value={props.value || ""}
          onChange={props.onChange}
          readOnly={props.readOnly}
          className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
        />
      </div>
    </div>
  );
}
