import { createSlice } from '@reduxjs/toolkit';
import { triggerNotification } from '../notification';
import { API_BASE_URL } from '../../../utils/constants';
import { sendRequest } from '../../../utils/http';

const userSlices = createSlice({
  name: 'users',
  initialState: {
    users: [],
    isLoading: false,
    isLoaded: false
  },
  reducers: {
    setUsers: (state, action) => {
      state.users = action.payload.users;
    },
    deleteUser: (state, action) => {
      state.users = state.users.filter(user => user.username !== action.payload.username);
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload.isLoading;
    },
    setIsLoaded: (state, action) => {
      state.isLoaded = action.payload.isLoaded;
    }
  }
});

export const getUsers = () => {
  return async (dispatch) => {
    const { response, responseError } = await sendRequest({
      url: `${API_BASE_URL}/cockpitUsers`,
      method: "GET"
    });

    if (
      response && response.status && response.data.data
    ) {
      dispatch(userSlices.actions.setUsers({ users: response.data.data }));
    } else {
      dispatch(triggerNotification(
        "Error", responseError.message,
        "error"
      ));
    }
    dispatch(userSlices.actions.setIsLoading({ isLoading: false }));
  }
};

export const deleteUser = (username) => {
  return async (dispatch) => {
    dispatch(userSlices.actions.setIsLoading({ isLoading: true }));
    const { response, responseError } = await sendRequest({
      url: `${API_BASE_URL}/cockpitUsers/${username}`,
      method: "DELETE"
    });
    if (
      response && response.status
    ) {
      dispatch(userSlices.actions.setIsLoaded({ isLoaded: true }));
      dispatch(userSlices.actions.deleteUser({ username }));
      dispatch(triggerNotification(
        "User deleted successfully!",
        response.message,
        "success"
      ));
    } else {
      dispatch(triggerNotification(
        "Error", responseError.message,
        "error"
      ));
    }
    dispatch(userSlices.actions.setIsLoading({ isLoading: false }));
  }
};

export const createUser = (user) => {
  return async (dispatch) => {
    dispatch(userSlices.actions.setIsLoading({ isLoading: true }));
    const { response, responseError } = await sendRequest({
      url: `${API_BASE_URL}/cockpitUsers`,
      method: "POST",
      body: user
    });
    if (
      response && response.status
    ) {
      dispatch(userSlices.actions.setIsLoaded({ isLoaded: true }));
      dispatch(getUsers());
      dispatch(triggerNotification(
        "User created successfully!",
        response.message,
        "success"
      ));
    } else {
      dispatch(triggerNotification(
        "Error", responseError.message,
        "error"
      ));
    }
    dispatch(userSlices.actions.setIsLoading({ isLoading: false }));
  }
};

export const updateUser = (user) => {
  return async (dispatch) => {
    dispatch(userSlices.actions.setIsLoading({ isLoading: true }));
    const { response, responseError } = await sendRequest({
      url: `${API_BASE_URL}/cockpitUsers/${user.username}`,
      method: "POST",
      body: user
    });
    if (
      response && response.status
    ) {
      dispatch(userSlices.actions.setIsLoaded({ isLoaded: true }));
      dispatch(getUsers());
      dispatch(triggerNotification(
        "User updated successfully!",
        response.message,
        "success"
      ));
    } else {
      dispatch(triggerNotification(
        "Error", responseError.message,
        "error"
      ));
    }
    dispatch(userSlices.actions.setIsLoading({ isLoading: false }));
  }
};


export const { setUsers } = userSlices.actions;
export default userSlices;