import { useReducer } from "react";
import { USER_FULL_PATH } from "../../../utils/constants";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import userSlices, { createUser } from "../../../store/modules/users/userSlice";
import { UserForm } from './UserForm';
import { formReducer } from "../reducers/userFormReducer";

export default function NewUser() {
  const navigate = useNavigate();
  const isLoaded = useSelector((state) => state.users.isLoaded);

  const [formState, dispatchForm] = useReducer(formReducer, {
    name: "",
    email: "",
    role: "Reviewer",
    validity: {
      name: true,
      email: true,
      role: true,
      isFormValid: true
    },
  });
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatchForm({ type: "FORM_SUBMIT" });
    await createNewUser();
  };

  const createNewUser = async () => {
    const data = {
      name: formState.name,
      email: formState.email,
      role: formState.role
    };
    console.log("createNewUser", data);
    dispatch(createUser(data));
  };

  const handleInputChange = (e) => {
    dispatchForm({
      type: "INPUT_CHANGE",
      value: e.target.value,
      input: e.target.name,
    });
  };

  if (isLoaded) {
    navigate(USER_FULL_PATH);
    dispatch(userSlices.actions.setIsLoaded({ isLoaded: false }));
  }

  return (
    <div className="p-6 container mx-auto ">
      <div className="bg-white overflow-hidden shadow rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <UserForm
            name={formState.name}
            email={formState.email}
            role={formState.role}
            title={formState.title}
            department={formState.department}
            onCancel={() => navigate(USER_FULL_PATH)}
            onInputChange={handleInputChange}
            onSubmit={handleSubmit}
            submitButtonText="Create User"
          />
        </div>
      </div>
    </div>
  );
}
