import { createSlice } from '@reduxjs/toolkit';

const modalSlice = createSlice({
    name: 'modals',
    initialState: {
        show: false,
        data: {},
    },
    reducers: {
        setModalOpen: (state, action) => {
            state.show = action.payload;
        },
        setModalData: (state, action) => {
            state.data = action.payload;
        }
    }
});

export const onModalConfirm = (callback = async () => { }, data) => {
    return async (dispatch) => {
        dispatch(modalSlice.actions.setModalOpen(false));
        await callback(data);
    }
}

export const onModalCancel = (callback = () => { }, data) => {
    return async (dispatch) => {
        dispatch(modalSlice.actions.setModalOpen(false));
        await callback(data);
    }
}

export const { setModalOpen, setModalData } = modalSlice.actions;
export default modalSlice;