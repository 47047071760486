import { Routes, Route, BrowserRouter } from "react-router-dom";
import React from "react";
// import jquery from "jquery";

import Login from "./pages/Login";
import Layout from "./pages/Layout";
import Dashboard from "./modules/Dashboard";

import JobLayout from "./modules/job/Layout";
import Job from "./modules/job/components/Job"
import CreateJob from "./modules/job/components/CreateJob";
import EditJob from "./modules/job/components/EditJob";
import BulkUploadEquipments from "./modules/job/components/BulkUploadJobs";

import Users from "./modules/users/components/Users";
import UserLayout from "./modules/users/Layout";
import EditUser from "./modules/users/components/EditUser";
import NewUser from './modules/users/components/CreateUser';

import AuthProvider from "./store/providers/AuthProvider";

import AuthChecker from "./components/AuthChecker";
import { DASHBOARD_ROUTE_PATH, ROOT_ROUTE_PATH, JOB_ROUTE_PATH, NEW_ROUTE_PATH, EDIT_ROUTE_PATH, BULK_UPLOAD_ROUTE_PATH, USER_ROUTE_PATH } from "./utils/constants";


function App() {

  return (

    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route path={ROOT_ROUTE_PATH} element={<Login />} />
          <Route path={DASHBOARD_ROUTE_PATH} element={<AuthChecker><Layout /></AuthChecker>}>
            <Route index element={<Dashboard />} />
            <Route path={JOB_ROUTE_PATH} element={<JobLayout />} >
              <Route index element={<Job />} />
              <Route path={NEW_ROUTE_PATH} element={<CreateJob />} />
              <Route path={EDIT_ROUTE_PATH} element={<EditJob />} />
              <Route path={BULK_UPLOAD_ROUTE_PATH} element={<BulkUploadEquipments />} />
            </Route>
            <Route path={USER_ROUTE_PATH} element={<UserLayout />} >
              <Route index element={<Users />} />
              <Route path={NEW_ROUTE_PATH} element={<NewUser />} />
              <Route path={EDIT_ROUTE_PATH} element={<EditUser />} />
            </Route>
          </Route>

        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}


export default App;

