import ButtonPrimary from "../buttons/ButtonPrimary";

/* This example requires Tailwind CSS v2.0+ */
export default function FileUpload(props) {
  const {
    buttonText,
    description,
    onChange,
    name,
    onSubmit,
    uploadedFileName,
    templateExcel
    
  } = props;
 console.log(props)
  return (
    <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
      <div className="space-y-1 text-center">
        <svg
          className="mx-auto h-12 w-12 text-gray-400"
          stroke="currentColor"
          fill="none"
          viewBox="0 0 48 48"
          aria-hidden="true"
        >
          <path
            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <div className="flex justify-center items-center">
          {uploadedFileName ? (
            <>
              <label
                htmlFor={name}
                className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
              >
                <span>Change</span>
                <input
                  id={name}
                  name={name}
                  type="file"
                  className="sr-only"
                  onChange={onChange}
                />
              </label>
              <p className="pl-1">
                <strong>File name: {uploadedFileName}</strong>
              </p>
            </>
          ) : (
            <>
              <label
                htmlFor={name}
                className="center relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
              >
                <span>Upload</span>
                <input
                  id={name}
                  name={name}
                  type="file"
                  className="sr-only"
                  onChange={onChange}
                />
              </label>
              <p className="pl-1">or drag and drop</p>
            </>
          )}
        </div>
        <p className="text-xs text-gray-500">{description}</p>
        <br />
        <ButtonPrimary title={buttonText} onClick={(e) => onSubmit(e)} />
        <div className="space-y-11 text-center">
        <label className="relative cursor-pointer justify-center ">
            Click <a href={templateExcel} className="relative cursor-pointer justify-center bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500" download="BulkUploadTemplate.xlsx">Here</a>To Download a Sample Bulk Upload Format
</label>
          </div>  
        </div>
        
    </div>
  );
}
