/* This example requires Tailwind CSS v2.0+ */
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FileUpload from "../../../components/ui/forms/FileUpload";
import * as XLSX from 'xlsx';
import { bulkUploadJobs } from "../../../store/modules/jobs/jobSlice"
import BulkUploadTemplate from "../assets/BulkUploadTemplate.xlsx";

export default function BulkUploadJobs() {
  const dispatch = useDispatch();
  const bulkUploadErrors = useSelector((state) => state.jobs.bulkUploadErrors);
  const [fileState, setFile] = useState(null);
  const [jobs, setJobs] = useState(null);


  const uploadFile = async (e) => {
    dispatch(bulkUploadJobs(jobs));
  };

  const onFileChange = (ev) => {
    let workBook = null;
    const reader = new FileReader();
    const file = ev.target.files[0];
    setFile(file);
    reader.onload = (event) => {
      const data = reader.result;
      workBook = XLSX.read(data, { type: 'binary' });
      let first_sheet_name = workBook.SheetNames[0];
      let worksheet = workBook.Sheets[first_sheet_name];
      let jsonObj = XLSX.utils.sheet_to_json(worksheet, { raw: false });
      setJobs(jsonObj)
    }
    reader.readAsBinaryString(file);
  }

  return (
    <div>
      <FileUpload
        buttonText="Confirm"
        title="Bulk Upload Jobs"
        description="Upload a CSV file."
        name="bulk-upload-jobs"
        onChange={(e) => onFileChange(e)}
        uploadedFileName={fileState ? fileState.name : null}
        onSubmit={uploadFile}
        templateExcel={BulkUploadTemplate}
      />
      {bulkUploadErrors?.length ? <table className="min-w-full divide-y divide-gray-300">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
            >
              Row
            </th>
            <th
              scope="col"
              className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              Field
            </th>
            <th
              scope="col"
              className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              Error
            </th>

          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">

          {bulkUploadErrors?.map((error, index) => {
            return <tr key={index}>
              <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-6">
                {error.row + 1}
              </td>
              <td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
                {error.column}
              </td>
              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">{error.message.message}</td>

            </tr>
          })}
        </tbody>
      </table> : null}
    </div>
  );
}
