export default function SelectInput(props) {
  return (
    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5 pb-4">
      <label
        htmlFor="email"
        className="block text-sm font-medium text-gray-700"
      >
        {props.title}
      </label>
      <select
        id={props.name}
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        disabled={props.disabled}
        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
      >
        {props.options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
}
