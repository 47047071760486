import Input from "../../../components/ui/inputs/Input";
import SelectInput from "../../../components/ui/inputs/SelectInput";
import { useReducer, useEffect } from "react";
import { JOB_FULL_PATH } from "../../../utils/constants";
import { useLocation, useNavigate } from "react-router-dom";
import MultiSelect from "../../../components/ui/inputs/MultiSelect";
import ButtonLight from "../../../components/ui/buttons/ButtonLight";
import ButtonPrimary from "../../../components/ui/buttons/ButtonPrimary";
import DateInput from "../../../components/ui/inputs/DateInput";
import {
  DEFAULT_CLIENTS,
  DEFAULT_JOB_TYPES,
  DEFAULT_PROJECT,
  DEFAULT_WORK_TYPES,
  ALLOWED_PRIORITY
} from "../constants";
import SimpleToggle from "../../../components/ui/toggles/SimpleToggle";
import TextArea from "../../../components/ui/forms/TextArea";
import { useDispatch, useSelector } from "react-redux";
import jobSlice, { updateJob } from "../../../store/modules/jobs/jobSlice";
import { getUsers } from "../../../store/modules/users/userSlice";
// import { getAllLocations } from "../../../store/modules/sites/locationsSlice";

const formReducer = (state, action) => {
  switch (action.type) {
    case "INPUT_CHANGE":
      return {
        ...state,
        [action.input]: action.value,
      };
    case "SUBMIT":
      return state;
    case "ADD_STAGE":
      const newStage = {
        name: "",
        assignees: [],
        showToCustomer: false,
        notes: "",
      };
      return {
        ...state,
        stages: [...state.stages, newStage],
      };
    case "REMOVE_STAGE":
      return {
        ...state,
        stages: state.stages.filter((stage, index) => index !== action.index),
      };
    case "STAGE_INPUT_CHANGE":
      const newStages = [...state.stages];
      newStages[action.index][action.input] = action.value;
      return {
        ...state,
        stages: newStages,
      };
    default:
      return state;
  }
};

export default function EditJob() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoaded = useSelector((state) => state.jobs.isLoaded);
  const users = useSelector((state) => state.users.users);

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  const HEADERS = [
    "Stage Level Index",
    "Stage Name",
    "Assignees",
    "Show to Customer",
    "Notes",
  ];

  const [formState, dispatchForm] = useReducer(formReducer, {
    clientName: state.clientName || DEFAULT_CLIENTS[0],
    patientName: state.patientName,
    jobType: state.jobType || DEFAULT_JOB_TYPES[0],
    project: state.project || DEFAULT_PROJECT[0],
    workType: state.workType || DEFAULT_WORK_TYPES[0],
    inputPages: state.inputPages || 1,
    eta: state.eta,
    receivedDate: state.receivedDate,
    priority: state.priority,
    stages: state.stages || [],
    currentStage: state.currentStage,
    currentAssignees: state.currentAssignees,
    completedDate: state.completedDate || "",
    uploadedDate: state.uploadedDate || "",
    reviewPageCount: state.reviewPageCount || 0,
    nonReviewPageCount: state.nonReviewPageCount || 0,
    duplicatePageCount: state.duplicatePageCount || 0,
    invoice: state.invoice || false,
    invoiceDate: state.invoiceDate || "",
    validity: {
      isFormValid: true,
    },
  });

  const handleInputChange = (e) => {
    dispatchForm({
      type: "INPUT_CHANGE",
      value: e.target.value,
      input: e.target.name,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatchForm({ type: "FORM_SUBMIT" });
    await editJob();
  };

  const editJob = async () => {
    const data = {
      id: state.id,
      clientName: formState.clientName,
      patientName: formState.patientName,
      jobType: formState.jobType,
      project: formState.project,
      workType: formState.workType,
      inputPages: formState.inputPages,
      eta: formState.eta,
      receivedDate: formState.receivedDate,
      priority: formState.priority,
      stages: formState.stages,
      currentStage: formState.currentStage,
      currentAssignees: formState.currentAssignees,
      completedDate: formState.completedDate,
      uploadedDate: formState.uploadedDate,
      reviewPageCount: formState.reviewPageCount,
      nonReviewPageCount: formState.nonReviewPageCount,
      duplicatePageCount: formState.duplicatePageCount,
      invoice: formState.invoice,
      invoiceDate: formState.invoiceDate,
    };
    dispatch(updateJob(data));
  };

  const handleStageInputChange = (e, index) => {
    dispatchForm({
      type: "STAGE_INPUT_CHANGE",
      value: e.target.value,
      input: e.target.name,
      index,
    });
  };

  const handleStageSelectChange = (selectedItems, index) => {
    dispatchForm({
      type: "STAGE_INPUT_CHANGE",
      value: selectedItems.values,
      input: selectedItems.name,
      index,
    });
  };
  const handleStageToggleChange = (e, index) => {
    dispatchForm({
      type: "STAGE_INPUT_CHANGE",
      value: e.value,
      input: e.name,
      index,
    });
  };

  if (isLoaded) {
    navigate(JOB_FULL_PATH);
    dispatch(jobSlice.actions.setIsLoaded({ isLoaded: false }));
  }

  const stages = formState.stages.map((stage, index) => {
    return (
      <tr key={{ index }}>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          {index + 1}
        </td>
        <td>
          <Input
            name="name"
            value={stage.name}
            onChange={(e) => handleStageInputChange(e, index)}
            className={"text-sm font-medium text-gray-900"}
          />
        </td>
        <td>
          <MultiSelect
            name="assignees"
            value={stage.assignees}
            onSelect={(e) => handleStageSelectChange(e, index)}
            onRemove={(e) => handleStageSelectChange(e, index)}
            options={users.map((user) => {
              return {
                id: user.id,
                email: user.email,
                name: user.name,
              };
            })}
          />
        </td>
        <td>
          <SimpleToggle
            name="showToCustomer"
            value={stage.showToCustomer}
            onChange={(e) => handleStageToggleChange(e, index)}
          />
        </td>
        <td>
          <TextArea
            name="notes"
            value={stage.notes}
            rows={3}
            onChange={(e) => handleStageInputChange(e, index)}
            className={"text-sm font-medium text-gray-900"}
          />
        </td>

        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
          <ButtonLight
            title="Remove"
            className={"text-red-400"}
            onClick={() => dispatchForm({ type: "REMOVE_STAGE", index })}
          />
        </td>
      </tr>
    );
  });

  return (
    <div className="p-6">
      <form className="space-y-8 divide-y divide-gray-200">
        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
          <div>
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Edit Job
              </h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">
                The information will be displayed to the customers so be careful
                what you enter.
              </p>
            </div>
            <SelectInput
              title="Client Name"
              name="clientName"
              value={formState.clientName}
              onChange={(e) => handleInputChange(e)}
              options={DEFAULT_CLIENTS.map((item) => ({
                value: item,
                label: item,
              }))}
            />
            <Input
              title="Patient Name"
              name="patientName"
              type="text"
              value={formState.patientName}
              onChange={(e) => handleInputChange(e)}
            />
            {/* TODO   OPTIONS NEEDS TO BE UPDATED */}
            <SelectInput
              title="Job Type"
              name="jobType"
              value={formState.jobType}
              onChange={(e) => handleInputChange(e)}
              options={DEFAULT_JOB_TYPES.map((item) => ({
                value: item,
                label: item,
              }))}
            />
            <SelectInput
              title="Project"
              name="project"
              value={formState.project}
              onChange={(e) => handleInputChange(e)}
              options={DEFAULT_PROJECT.map((item) => ({
                value: item,
                label: item,
              }))}
            />
            <SelectInput
              title="Work Type"
              name="workType"
              value={formState.workType}
              onChange={(e) => handleInputChange(e)}
              options={DEFAULT_WORK_TYPES.map((item) => ({
                value: item,
                label: item,
              }))}
            />
            <SelectInput
              title="Priority"
              name="priority"
              value={formState.priority}
              onChange={(e) => handleInputChange(e)}
              options={ALLOWED_PRIORITY.map((item) => ({
                value: item,
                label: item,
              }))}
            />
            <Input
              title="Input Pages"
              name="inputPages"
              type="number"
              value={formState.inputPages}
              onChange={(e) => handleInputChange(e)}
            />
            <DateInput
              title="Received At"
              name="receivedDate"
              value={formState.receivedDate ? `${new Date(formState.receivedDate).getFullYear()}-${("0" + (new Date(formState.receivedDate).getMonth() + 1)).slice(-2)}-${("0" + new Date(formState.receivedDate).getDate()).slice(-2)}` : formState.receivedDate}
              onChange={(e) => handleInputChange(e)}
            />
            <DateInput
              title="ETA"
              name="eta"
              value={formState.eta ? `${new Date(formState.eta).getFullYear()}-${("0" + (new Date(formState.eta).getMonth() + 1)).slice(-2)}-${("0" + new Date(formState.eta).getDate()).slice(-2)}` : formState.eta}
              onChange={(e) => handleInputChange(e)}
            />
            
            <Input
              title="Review Page Count"
              name="reviewPageCount"
              type="number"
              readonly
              value={formState.reviewPageCount}
              onChange={(e) => handleInputChange(e)}
            />
            <Input
              title="Non-Review Page Count"
              name="nonReviewPageCount"
              type="number"
              readonly
              value={formState.nonReviewPageCount}
              onChange={(e) => handleInputChange(e)}
            />
            <Input
              title="Duplicate Page Count"
              name="duplicatePageCount"
              type="number"
              readonly
              value={formState.duplicatePageCount}
              onChange={(e) => handleInputChange(e)}
            />
            <SelectInput
              title="Current Stage"
              name="currentStage"
              value={formState.currentStage}
              onChange={(e) => handleInputChange(e)}
              options={formState.stages.map((stage) => {
                return {
                  value: stage.name,
                  label: stage.name,
                };
              })}
            />
            <DateInput
              title="Invoice Date"
              name="invoiceDate"
              value={formState.invoiceDate ? `${new Date(formState.invoiceDate).getFullYear()}-${("0" + (new Date(formState.invoiceDate).getMonth() + 1)).slice(-2)}-${("0" + new Date(formState.invoiceDate).getDate()).slice(-2)}` : formState.invoiceDate}
              onChange={(e) => handleInputChange(e)}
            />

            <DateInput
              title="Completed Date"
              name="completedDate"
              value={formState.completedDate ? `${new Date(formState.completedDate).getFullYear()}-${("0" + (new Date(formState.completedDate).getMonth() + 1)).slice(-2)}-${("0" + new Date(formState.completedDate).getDate()).slice(-2)}` : formState.completedDate}
              onChange={(e) => handleInputChange(e)}
            />
            
            <div className="h-full">
              <table className="min-w-full divide-gray-200 table-auto mt-6">
                <thead className="bg-gray-50">
                  <tr>
                    {HEADERS.map((header) => (
                      <th
                        key={header}
                        className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {header}
                      </th>
                    ))}
                    <th>
                      <div className="flex justify-end">
                        <ButtonPrimary
                          title="Add new Stage"
                          onClick={() => {
                            dispatchForm({
                              type: "ADD_STAGE",
                            });
                          }}
                        />
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {stages}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="pt-5">
          <div className="flex justify-end">
            <button
              type="button"
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={() => navigate(JOB_FULL_PATH)}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={handleSubmit}
            >
              Update Job
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
