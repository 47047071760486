import { isValidEmail } from "../../../utils/global";

export const formReducer = (state, action) => {
    switch (action.type) {
        case "INPUT_CHANGE":
            return {
                ...state,
                [action.input]: action.value,
            };
        case "FORM_SUBMIT":
            const isEmailValid = isValidEmail(state.email);
            const isFormValid =
                state.name &&
                state.email &&
                isEmailValid &&
                state.title &&
                state.department;
            return {
                name: state.name,
                email: state.email,
                title: state.title,
                department: state.department,
                validity: {
                    name: !!state.name,
                    email: isEmailValid,
                    title: !!state.title,
                    department: !!state.department,
                    isFormValid: !!isFormValid,
                },
            };
        default:
            return state;
    }
};