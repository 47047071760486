export default function ButtonLight(props) {
  return (
    <button
      type={props.type || "button"}
      onClick={props.onClick}
      className={`bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${props.className}`}
    >
      {props.title}
    </button>
  );
}
