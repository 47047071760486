export const BadgeLarge = (props) => {
  const bgColor = props.bgColor || "bg-indigo-100";
  const textColor = props.textColor || "text-indigo-800";
  return (
    <span
      className={`inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium ${bgColor} ${textColor} ${props.className}`}
    >
      {props.isDot ? (
        <svg
          className="-ml-0.5 mr-1.5 h-2 w-2 text-indigo-400"
          fill="currentColor"
          viewBox="0 0 8 8"
        >
          {" "}
          <circle cx={4} cy={4} r={3} />{" "}
        </svg>
      ) : null}
      {props.title}
    </span>
  );
};
