
import { Fragment, useState, useReducer } from 'react'

import Input from "../../../components/ui/inputs/Input";
import { Dialog, Transition } from '@headlessui/react'
import { useDispatch, } from "react-redux";
import { getJobs } from "../../../store/modules/jobs/jobSlice";
import SelectInput from "../../../components/ui/inputs/SelectInput";
import {
    DEFAULT_JOB_TYPES,
    DEFAULT_PROJECT,
    DEFAULT_CLIENTS,
    DEFAULT_WORK_TYPES,
    ALLOWED_PRIORITY
} from "../constants";

const formReducer = (state, action) => {
    switch (action.type) {
        case "INPUT_CHANGE":
            return {
                ...state,
                [action.input]: action.value,
            };
        case "SUBMIT":
            return state;

        case "FORM_CLEAR":
            return {
                clientName: "",
                jobType: "",
                workType: "",
                priority: "",
                project: "",
            };
        default:
            return state;
    }
};

export default function JobFilters() {
    let [isOpen, setIsOpen] = useState(false)
    const dispatch = useDispatch();

    function closeModal() {
        setIsOpen(false)
    }

    function openModal() {
        setIsOpen(true)
    }

    const handleInputChange = (e) => {
        dispatchForm({
            type: "INPUT_CHANGE",
            value: e.target.value,
            input: e.target.name,
        });
    };


    const [formState, dispatchForm] = useReducer(formReducer, {
        clientName: null,
        patientName: null,
        jobType: null,
        project: null,
        workType: null,
        priority: null,
        currentStage: null
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        dispatchForm({ type: "SUBMIT" });
        await filterJobs();
    };

    const clearFilter = async (e) => {
        e.preventDefault();
        dispatchForm({ type: "FORM_CLEAR" });
        dispatch(getJobs({}));
    }

    const filterJobs = async () => {
        const filters = {
            clientName: formState.clientName && formState.clientName.length > 0 ? formState.clientName : null,
            patientName: formState.patientName && formState.patientName.length > 0 ? formState.patientName : null,
            jobType: formState.jobType && formState.jobType.length > 0 ? formState.jobType : null,
            project: formState.project && formState.project.length > 0 ? formState.project : null,
            workType: formState.workType && formState.workType.length > 0 ? formState.workType : null,
            priority: formState.priority && formState.priority.length > 0 ? formState.priority : null,
            currentStage: formState.currentStage && formState.currentStage.length > 0 ? formState.currentStage : null,
        };
        
        dispatch(getJobs(filters));
        closeModal();
    };

    return (
        <>
            <div className="">
                <button
                    type="button"
                    onClick={openModal}
                    className="text-indigo-600 hover:text-indigo-700 "
                >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z" />
                    </svg>
                </button>
            </div>

            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-medium leading-6 text-gray-900"
                                    >
                                        Job Filters
                                    </Dialog.Title>
                                    <div className='filterForm'>
                                        <SelectInput
                                            title="Client Name"
                                            name="clientName"
                                            value={formState.clientName}
                                            onChange={(e) => handleInputChange(e)}
                                            options={[{ value: "", label: "" }, ...DEFAULT_CLIENTS.map((item) => ({
                                                value: item,
                                                label: item,
                                            }))]}
                                        />

                                        <Input
                                            title="Patient Name"
                                            name="patientName"
                                            type="text"
                                            value={formState.patientName}
                                            onChange={(e) => handleInputChange(e)}
                                        />

                                        <SelectInput
                                            title="Job Type"
                                            name="jobType"
                                            value={formState.jobType}
                                            onChange={(e) => handleInputChange(e)}
                                            options={[{ value: "", label: "" }, ...DEFAULT_JOB_TYPES.map((item) => ({
                                                value: item,
                                                label: item,
                                            }))]}
                                        />

                                        <SelectInput
                                            title="Work Type"
                                            name="workType"
                                            value={formState.workType}
                                            onChange={(e) => handleInputChange(e)}
                                            options={[{ value: "", label: "" }, ...DEFAULT_WORK_TYPES.map((item) => ({
                                                value: item,
                                                label: item,
                                            }))]}
                                        />
                                        <SelectInput
                                            title="Project"
                                            name="project"
                                            value={formState.project}
                                            onChange={(e) => handleInputChange(e)}
                                            options={[{ value: "", label: "" }, ...DEFAULT_PROJECT.map((item) => ({
                                                value: item,
                                                label: item,
                                            }))]}
                                        />
                                        <SelectInput
                                            title="Priority"
                                            name="priority"
                                            value={formState.priority}
                                            onChange={(e) => handleInputChange(e)}
                                            options={[{value: "", label: ""}, ...ALLOWED_PRIORITY.map((item) => ({
                                                value: item,
                                                label: item,
                                            }))]}
                                        />

                                        <Input
                                            title="Current Stage"
                                            name="currentStage"
                                            type="text"
                                            value={formState.currentStage}
                                            onChange={(e) => handleInputChange(e)}
                                        />

                                    </div>

                                    <div className="mt-4">
                                        <button
                                            type="button"
                                            className="mr-4 inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                            onClick={closeModal}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="button"
                                            className="mr-4 inline-flex justify-center rounded-md border border-transparent text-blue-900 bg-blue-100 hover:bg-blue-200 px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                            onClick={clearFilter}
                                        >
                                            Clear Filter
                                        </button>
                                        <button
                                            type="button"
                                            className="inline-flex justify-center rounded-md border border-transparent text-white bg-indigo-600 hover:bg-indigo-700 px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                            onClick={handleSubmit}
                                        >
                                            Apply Filter
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}
