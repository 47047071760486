import { useReducer } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import { USER_FULL_PATH } from "../../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import userSlices, { updateUser } from "../../../store/modules/users/userSlice";
import { UserForm } from "./UserForm";
import { formReducer } from "../reducers/userFormReducer";

export default function EditUser() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoaded = useSelector((state) => state.users.isLoaded);

  const [formState, dispatchForm] = useReducer(formReducer, {
    name: state.user.name,
    email: state.user.email,
    role: state.user.role,
    validity: {
      name: true,
      email: true,
      role: true,
      isFormValid: true,
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatchForm({ type: "FORM_SUBMIT" });
    await updateUserHandler();
  };

  const updateUserHandler = async () => {
    const data = {
      username: state.user.username,
      name: formState.name,
      email: formState.email,
      role: formState.role
    };
    console.log("updateUser", data);
    dispatch(updateUser(data));
  };

  const handleInputChange = (e) => {
    dispatchForm({
      type: "INPUT_CHANGE",
      value: e.target.value,
      input: e.target.name,
    });
  };

  if (isLoaded) {
    navigate(USER_FULL_PATH);
    dispatch(userSlices.actions.setIsLoaded({ isLoaded: false }));
  }

  return (
    <div className="p-6 container mx-auto ">
      <div className="bg-white overflow-hidden shadow rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <UserForm
            name={formState.name}
            email={formState.email}
            role={formState.role}
            onCancel={() => navigate(USER_FULL_PATH)}
            onInputChange={handleInputChange}
            onSubmit={handleSubmit}
            submitButtonText="Update User"
          />
        </div>
      </div>
    </div>
  );
}
