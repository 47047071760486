import ButtonLight from "../../../components/ui/buttons/ButtonLight";
import ButtonPrimary from "../../../components/ui/buttons/ButtonPrimary";
import Input from "../../../components/ui/inputs/Input";
import SelectInput from "../../../components/ui/inputs/SelectInput";
import { ROLES } from "../constants";

export const UserForm = (props) => {
    const { submitButtonText = "",onInputChange} = props;
  return (
    <form className="space-y-8 divide-y divide-gray-200">
      <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
        <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Update User
            </h3>
          </div>

          <div className="space-y-6 sm:space-y-5">
            <Input
              title="Name"
              value={props.name}
              name="name"
              onChange={(e) => onInputChange(e)}
            />
            <Input
              title="Email address"
              value={props.email}
              name="email"
              type="email"
              onChange={(e) => onInputChange(e)}
            />
            <SelectInput
              title="Role"
              name="role"
              value={props.role}
              onChange={(e) => onInputChange(e)}
              options={ROLES.map((item) => ({
                value: item,
                label: item,
              }))}
            />
          </div>
        </div>
      </div>

      <div className="pt-5">
        <div className="flex justify-end">
          <ButtonLight title="Cancel" onClick={props.onCancel} />
          <ButtonPrimary
            className="ml-3"
            title={submitButtonText}
            onClick={props.onSubmit}
            type="submit"
          />
        </div>
      </div>
    </form>
  );
};
