import { useLocation } from "react-router-dom";

export default function PageTitle() {
  const location = useLocation();
  const pathChunks = location.pathname.split("/");
  const pageTitle = pathChunks[pathChunks.length - 1];

  // Make first character to upcase
  const pageTitleUpcase = pageTitle.charAt(0).toUpperCase() + pageTitle.slice(1)

  return <h2 className="text-2xl font-bold leading-7 text-white sm:text-3xl sm:truncate">{pageTitleUpcase}</h2>

}