export default function TextArea(props) {
  const { name, value, onChange, placeholder, title, rows } = props;
  return (
    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 sm:pt-5">
      <label
        htmlFor={name}
        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
      >
        {title}
      </label>
      <textarea
        id={name}
        name={name}
        rows={rows || 3}
        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md"
        defaultValue={value}
        onChange={onChange}
        placeholder={placeholder}
      />
    </div>
  );
}
