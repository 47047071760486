import { createSlice } from '@reduxjs/toolkit';
import { triggerNotification } from '../notification';
import { API_BASE_URL } from '../../../utils/constants';
import { sendRequest } from '../../../utils/http';
import { JOBS_API_URL, CURRENT_ASSIGNEE_JOBS_API_URL } from '../../../modules/job/constants';
import { getCookie } from '../../../utils/global';
const jobSlice = createSlice({
  name: 'jobs',
  initialState: {
    jobs: [],
    bulkUploadErrors: [],
    isLoading: false,
    isLoaded: false,
    comments: []
  },
  reducers: {
    setJobs: (state, action) => {
      state.jobs = action.payload.jobs;
    },
    setComments: (state, action) => {
      state.comments = action.payload.comments;
    },
    deleteJob: (state, action) => {
      state.jobs = state.jobs.filter(job => job.id !== action.payload.id);
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload.isLoading;
    },
    setBulkUploadErrors: (state, action) => {
      state.bulkUploadErrors = action.payload.bulkUploadErrors;
    },
    setIsLoaded: (state, action) => {
      state.isLoaded = action.payload.isLoaded;
    },
  }
});

export const getJobs = (filters) => {
  return async (dispatch) => {
    let filterQuery = [];
    dispatch(jobSlice.actions.setBulkUploadErrors({ bulkUploadErrors: [] }));
    if (filters) {
      if (filters.clientName) {
        if (filters.clientName === "H&C") {
          filterQuery.push(`clientName=H%26C`);
        } else {
          filterQuery.push(`clientName=${filters.clientName}`);
        }

      }
      if (filters.patientName) {
        filterQuery.push(`patientName=${filters.patientName}`);
      }
      if (filters.jobType) {
        filterQuery.push(`jobType=${filters.jobType}`);
      }
      if (filters.workType) {
        filterQuery.push(`workType=${filters.workType}`);
      }
      if (filters.priority) {
        filterQuery.push(`priority=${filters.priority}`);
      }
      if (filters.project) {
        filterQuery.push(`project=${filters.project}`);
      }
      if (filters.currentStage) {
        filterQuery.push(`currentStage=${filters.currentStage}`);
      }
    }
    const { response, responseError } = await sendRequest({
      url: JOBS_API_URL + "?" + filterQuery.join("&"),
      method: "GET"
    });
    if (
      response && response.status && response.data.data &&
      response.data.data.jobs && response.data.data.jobs.length
    ) {
      dispatch(jobSlice.actions.setJobs({ jobs: response.data.data.jobs }));
    } else {
      dispatch(triggerNotification(
        "Error", responseError.message,
        "error"
      ));
    }
    dispatch(jobSlice.actions.setIsLoading({ isLoading: false }));
  }
};

export const getCurrentAssigneeJobs = () => {
  return async (dispatch) => {
    const { response, responseError } = await sendRequest({
      url: `${CURRENT_ASSIGNEE_JOBS_API_URL}?currentAssignee=${getCookie("uid")}`,
      method: "GET"
    });
    if (
      response && response.status && response.data.data &&
      response.data.data.jobs
    ) {
      dispatch(jobSlice.actions.setJobs({ jobs: response.data.data.jobs }));
    } else {
      dispatch(triggerNotification(
        "Error", responseError.message,
        "error"
      ));
    }
    dispatch(jobSlice.actions.setIsLoading({ isLoading: false }));
  }
};

export const deleteJob = (id) => {
  return async (dispatch) => {
    dispatch(jobSlice.actions.setIsLoading({ isLoading: true }));
    const { response, responseError } = await sendRequest({
      url: `${JOBS_API_URL}/${id}`,
      method: "DELETE"
    });
    if (
      response && response.status
    ) {
      dispatch(jobSlice.actions.setIsLoaded({ isLoaded: true }));
      dispatch(jobSlice.actions.deleteJob({ id }));
      dispatch(triggerNotification(
        "Job deleted successfully!",
        response.message,
        "success"
      ));
    } else {
      dispatch(triggerNotification(
        "Error", responseError.message,
        "error"
      ));
    }
    dispatch(jobSlice.actions.setIsLoading({ isLoading: false }));
  }
};

export const createJobs = (job) => {
  return async (dispatch) => {
    dispatch(jobSlice.actions.setIsLoading({ isLoading: true }));
    let currentAssignees = [];
    let currentStage = "";
    if (job.stages.length) {
      currentStage = job.stages[0].name;
      job.stages.forEach((stage) => {
        stage.assignees.forEach(assignee => {
          currentAssignees.push(assignee.email);
        })
      })
    }
    const { response, responseError } = await sendRequest({
      url: `${API_BASE_URL}/jobs`,
      method: "POST",
      body: { ...job, currentStage, currentAssignees: currentAssignees.join(",") }
    });
    if (
      response && response.status
    ) {
      dispatch(jobSlice.actions.setIsLoaded({ isLoaded: true }));
      dispatch(triggerNotification(
        "Job created successfully!",
        response.message,
        "success"
      ));
    } else {
      dispatch(triggerNotification(
        "Error", responseError.message,
        "error"
      ));
    }
    dispatch(jobSlice.actions.setIsLoading({ isLoading: false }));
  }
};

export const bulkUploadJobs = (jobs) => {
  return async (dispatch) => {
    dispatch(jobSlice.actions.setIsLoading({ isLoading: true }));
    dispatch(jobSlice.actions.setBulkUploadErrors({ bulkUploadErrors: [] }));
    console.log(jobs)
    let transformedJobs = [];
    const email = getCookie("uid");
    if (jobs.length <= 25) {

      jobs.forEach(_job => {
        transformedJobs.push({
          clientName: _job["Client"],
          patientName: _job["Patient"],
          jobType: _job["Jobtype"],
          project: _job["Project"] && _job["Project"].length ? _job["Project"] : "Not Applicable",
          workType: _job["Worktype"],
          inputPages: _job["Input Pages"],
          priority: _job["Priority"] && _job["Priority"].length ? _job["Priority"] : "Normal",
          eta: _job["ETA"] ? new Date(_job["ETA"]).toISOString() : "",
          currentStage: _job["Work Stage"] && _job["Work Stage"].length ? _job["Work Stage"] : "New",
          completedDate: "",
          uploadedDate: _job["Upload Date"] ? new Date(_job["Upload Date"]).toISOString() : "",
          reviewPageCount: _job["Review"],
          nonReviewPageCount: _job["Non Review"],
          duplicatePageCount: _job["Duplicate"],
          invoice: _job["Invoice Date"] && _job["Invoice Date"].length ? 1 : 0,
          invoiceDate: _job["Invoice Date"] ? new Date(_job["Invoice Date"]).toISOString() : "",
          currentAssignees: _job["Assignee"] && _job["Assignee"].length ? _job["Assignee"] : email,
          receivedDate: _job["Received Date"] ? new Date(_job["Received Date"]).toISOString() : ""
        })
      })
      console.log(transformedJobs);
      const { response } = await sendRequest({
        url: `${API_BASE_URL}/jobs/bulkUpload`,
        method: "POST",
        body: {
          jobs: transformedJobs
        },
      });
  
      if (response && response.status && response.data.status) {
        dispatch(triggerNotification(
          "Jobs uploaded successfully!",
          response.message,
          "success"
        ));
      } else {
        dispatch(jobSlice.actions.setBulkUploadErrors({ bulkUploadErrors: response.data?.errors }));
        dispatch(triggerNotification(
          "Job not uploaded successfully!",
          "error"
        ));
      }
    } else {
        dispatch(triggerNotification(
          "Sorry, can't upload more than 25 jobs at a time!",
          "error"
        ));
    }
    
  } 
};

export const updateJob = (data) => {
  return async (dispatch) => {
    let currentAssignees = [];
    if (data.stages.length) {
      // eslint-disable-next-line
      data.stages.find((stage) => {
        if (stage.name === data.currentStage) {
          stage.assignees.forEach(assignee => {
            currentAssignees.push(assignee.email);
          })
        }
      })
    }
    dispatch(jobSlice.actions.setIsLoading({ isLoading: true }));
    const { response, responseError } = await sendRequest({
      url: `${JOBS_API_URL}/${data.id}`,
      method: "POST",
      body: { ...data, currentAssignees: currentAssignees.join(",") }
    });
    if (
      response && response.status
    ) {
      dispatch(jobSlice.actions.setIsLoaded({ isLoaded: true }));
      dispatch(getJobs());
      dispatch(triggerNotification(
        "Job updated successfully!",
        response.message,
        "success"
      ));
    } else {
      dispatch(triggerNotification(
        "Error", responseError.message,
        "error"
      ));
    }
    dispatch(jobSlice.actions.setIsLoading({ isLoading: false }));
  }
};

export const addComment = (jobId, payload) => {
  return async (dispatch) => {
    dispatch(jobSlice.actions.setIsLoading({ isLoading: true }));
    const { response, responseError } = await sendRequest({
      url: `${API_BASE_URL}/comments`,
      method: "POST",
      body: { jobId, comment: payload.comment, isPrivate: payload.isPrivate, creator: getCookie("uid") }
    });
    if (
      response && response.status
    ) {
      dispatch(getComments(jobId))
      dispatch(jobSlice.actions.setIsLoaded({ isLoaded: true }));
      dispatch(triggerNotification(
        "Comment created successfully!",
        response.message,
        "success"
      ));
    } else {
      dispatch(triggerNotification(
        "Error", responseError.message,
        "error"
      ));
    }
    dispatch(jobSlice.actions.setIsLoading({ isLoading: false }));
  }
}

export const getComments = (jobId) => {
  return async (dispatch) => {
    dispatch(jobSlice.actions.setComments({ comments: [] }));
    const { response, responseError } = await sendRequest({
      url: `${API_BASE_URL}/comments?jobId=${jobId}`,
      method: "GET"
    });
    if (
      response && response.status && response.data.data &&
      response.data.data.jobs && response.data.data.jobs.length
    ) {
      dispatch(jobSlice.actions.setComments({ comments: response.data.data.jobs }));
    } else {
      dispatch(triggerNotification(
        "Error", responseError.message,
        "error"
      ));
    }
    dispatch(jobSlice.actions.setIsLoading({ isLoading: false }));
  }
};

export const { setJobs, setIsLoaded, setIsLoading } = jobSlice.actions;
export default jobSlice;