export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// ROUTER PATHS

export const ROOT_ROUTE_PATH = "/";
export const DASHBOARD_ROUTE_PATH = "dashboard"
export const JOB_ROUTE_PATH = `jobs`
export const USER_ROUTE_PATH = "users"
export const NEW_ROUTE_PATH = "new"
export const EDIT_ROUTE_PATH = "edit"
export const BULK_UPLOAD_ROUTE_PATH = "bulkUpload"

export const ROOT_PATH = "/";
export const DASHBOARD_PATH = `/${DASHBOARD_ROUTE_PATH}`
export const JOB_PATH = `/${JOB_ROUTE_PATH}`
export const USER_PATH = `/${USER_ROUTE_PATH}`
export const NEW_PATH = `/${NEW_ROUTE_PATH}`



export const JOB_FULL_PATH = `${DASHBOARD_PATH}${JOB_PATH}`;
export const USER_FULL_PATH = `${DASHBOARD_PATH}${USER_PATH}`;

export const JOB_NEW_FULL_PATH = `${DASHBOARD_PATH}${JOB_PATH}${NEW_PATH}`;
export const USER_NEW_FULL_PATH = `${DASHBOARD_PATH}${USER_PATH}${NEW_PATH}`;