import { configureStore } from '@reduxjs/toolkit';
import notification from './modules/notification';
import userSlices from './modules/users/userSlice';
import modalSlice from './modules/modalSlice';
import jobSlice from './modules/jobs/jobSlice';

const store = configureStore({
  reducer: {
    notification: notification.reducer,
    users: userSlices.reducer,
    modal: modalSlice.reducer,
    jobs: jobSlice.reducer
  },
});

export default store;