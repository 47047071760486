import { useNavigate } from "react-router-dom";
import { Fragment, useEffect, useState } from "react";
import { classNames } from "../../../utils/global";
import { Menu, Transition } from "@headlessui/react";
import { DotsHorizontalIcon } from "@heroicons/react/solid";
import { BadgeLarge } from "../../../components/ui/badges/BadgeLarge";
import { EDIT_ROUTE_PATH, NEW_ROUTE_PATH, BULK_UPLOAD_ROUTE_PATH } from "../../../utils/constants";
import { getJobs, deleteJob, getComments, getCurrentAssigneeJobs } from "../../../store/modules/jobs/jobSlice";
import { useDispatch, useSelector } from "react-redux";
import ConfirmDeleteModal from "../../../components/ui/modals/ConfirmDeleteModal";
import { setModalData, setModalOpen } from "../../../store/modules/modalSlice";
import Comments from "./Comments";
import JobFilters from "./JobFilters";

let tabs = [
  { name: 'All Jobs', current: true },
  { name: 'My Jobs', current: false }
]

export default function Job() {
  const [open, setOpen] = useState(false)
  const [jobId, setJobId] = useState(null);


  const navigate = useNavigate();
  const jobs = useSelector((state) => state.jobs.jobs);
  const dispatch = useDispatch();

  const _showComments = async (jobId) => {
    dispatch(getComments(jobId))
    setOpen(true);
    setJobId(jobId)
  }

  useEffect(() => {
    dispatch(getJobs());
  }, [dispatch]);

  const _deleteJob = async (data) => {
    dispatch(deleteJob(data.id));
  };

  const HEADERS = [
    "Client",
    "Patient",
    "Priority",
    "Project / Work Type",
    "Job Type",
    "Received Date",
    "ETA",
    "Completed Date",
    "Input Pages",
    "Assignees",
    "Current Stage",
    "Review",
    "Non-Review",
    "Duplicate",
    "Invoice Date",
    "ACTIONS",
  ];

  const locations = (locations, field) => {
    if (!locations) {
      return (
        <div>
          <BadgeLarge title={"-"} isDot={false} className={"ml-2 mt-1"} />
          <br />
        </div>
      );
    }
    if (locations && Array.isArray(locations)) {
      if (field === "assignees") {
        return (
          <div>
            {locations.map((location, index) => (
              <div key={index}>
                <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-blue-100 text-blue-800 my-1">
                  {location.split("@")[0]}
                </span>
              </div>
            ))}
            <br />
          </div>
        );
      } else {
        return (
          <div>
            {locations.map((location, index) => (
              <div key={index}>
                <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-blue-100 text-blue-800 my-1">
                  {location}
                </span>
              </div>
            ))}
            <br />
          </div>
        );
      }
    } else if (locations && typeof locations === "string") {
      return (
        <div>
          <BadgeLarge title={locations} className={"ml-2 mt-1"} />
          <br />
        </div>
      );
    }
  };

  const filterJobs = (e, name) => {
    if (name === "My Jobs") {
      dispatch(getCurrentAssigneeJobs());
      tabs = [
        { name: 'All Jobs', current: false },
        { name: 'My Jobs', current: true }
      ]
    } else if (name === "All Jobs") {
      dispatch(getJobs());
      tabs = [
        { name: 'All Jobs', current: true },
        { name: 'My Jobs', current: false }
      ]
    }
  }
  const constructDate = (date) => {
    if (date) {
      date = new Date(date);
      return `${("0" + (date.getMonth() + 1)).slice(-2)}-${("0" + (date.getDate())).slice(-2)}-${date.getFullYear().toString().substr(-2)}`
    }
    return ""
  }

  return (
    <>
      <ConfirmDeleteModal
        title="Delete Job"
        message="Are you sure you want to delete this job?"
        onConfirm={(data) => {
          _deleteJob(data);
        }}
      />

      <div className="relative p-5 border-b border-gray-200 sm:pb-0 overflow-y-scroll w-full" style={{ "height": "68vh" }}>
        <div className="md:flex md:items-center md:justify-between">
          <div>
            <div className="hidden sm:block">
              <nav className="flex space-x-4" aria-label="Tabs">
                {tabs.map((tab) => (
                  <button
                    key={tab.name}
                    href={tab.href}
                    className={classNames(
                      tab.current ? 'bg-indigo-100 text-indigo-700' : 'text-gray-500 hover:text-gray-700',
                      'px-3 py-2 font-medium text-sm rounded-md'
                    )}
                    onClick={(e) => filterJobs(e, tab.name)}
                    aria-current={tab.current ? 'page' : undefined}
                  >
                    {tab.name}
                  </button>
                ))}
                <JobFilters />
              </nav>
            </div>
          </div>
          <div className="p-3 flex md:mt-0 md:absolute md:top-3 md:right-0">
            {/* <span className="relative z-0 inline-flex shadow-sm rounded-md">
              <button
                type="button"
                className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={async (event) => {
                  alert("TBD");
                }}
              >
                Export
              </button>
            </span> */}
            <span className="relative z-0 inline-flex shadow-sm rounded-md">
              <button
                type="button"
                className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={() => {
                  navigate(BULK_UPLOAD_ROUTE_PATH);
                }}
              >
                Bulk Upload
              </button>
              <button
                type="button"
                className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={() => {
                  navigate(NEW_ROUTE_PATH);
                }}
              >
                Create New Job
              </button>

            </span>
          </div>
        </div>
        <div className="mt-4">
          {/* Start of table */}
          <div className="h-full">
            <table className="min-w-full divide-gray-200">
              <thead className="bg-gray-50">
                <tr className="divide-x divide-gray-200">
                  {HEADERS.map((header, index) => (
                    <th
                      key={index}
                      scope="col"
                      style={{top: "-20px"}}
                      className="sticky z-10 border-b border-gray-300 bg-gray-50 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900  sm:pl-6 lg:pl-8"
                    >
                      {header}
                    </th>
                  ))}

                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {jobs &&
                  jobs.map((job) => (
                    <tr key={job.id} className="divide-x divide-gray-200">
                      <td className=" whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          {job.clientName}
                        </div>
                      </td>
                      <td className=" whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          {job.patientName}
                        </div>
                      </td>
                      <td className=" whitespace-nowrap">
                        <div className="text-sm text-gray-900">{job.priority === "Normal" ? <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                          {job.priority}
                        </span> : <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                          {job.priority}
                        </span>}</div>
                      </td>
                      <td className=" whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          {job.project}
                          <p>{job.workType}</p>
                        </div>
                      </td>
                      <td className=" whitespace-nowrap">
                        {job.jobType}
                      </td>
                      <td className=" whitespace-nowrap">
                        <div className="text-sm text-gray-900">{job.receivedDate ? constructDate(job.receivedDate) : ""}</div>
                      </td>
                      <td className=" whitespace-nowrap">
                        <div className="text-sm text-gray-900">{job.eta ? constructDate(job.eta) : ""}</div>
                      </td>
                      <td className=" whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          {constructDate(job.completedDate)}
                        </div>
                      </td>

                      <td className=" whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          {job.inputPages}
                        </div>
                      </td>
                      <td className=" whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          {locations(job.currentAssignees?.split(","), "assignees")}
                        </div>
                      </td>
                      <td className=" whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          {locations(job.currentStage, "stage")}
                        </div>
                      </td>

                      <td className=" whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          {job.reviewPageCount}
                        </div>
                      </td>

                      <td className=" whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          {job.nonReviewPageCount}
                        </div>
                      </td>

                      <td className=" whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          {job.duplicatePageCount}
                        </div>
                      </td>
                    
                      
                      <td className=" whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          {constructDate(job.invoiceDate)}
                        </div>
                      </td>


                      <td className=" whitespace-nowrap text-right text-sm font-medium">
                        <Menu
                          as="div"
                          className="absolute top-6 right-0 xl:relative xl:top-auto xl:right-auto xl:self-center"
                        >
                          <div>
                            <Menu.Button className="-m-2 flex items-center rounded-full p-2 text-gray-500 hover:text-gray-600">
                              <span className="sr-only">Open options</span>
                              <DotsHorizontalIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </Menu.Button>
                          </div>

                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="focus:outline-none absolute right-0 z-10 mt-2 w-24 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                              <div className="py-1">
                                <Menu.Item>
                                  {({ active }) => (
                                    <button
                                      className={classNames(
                                        active
                                          ? "bg-gray-100 text-gray-900"
                                          : "text-gray-700",
                                        "block px-4 py-2 text-sm text-center"
                                      )}
                                      onClick={() => {
                                        navigate(EDIT_ROUTE_PATH, {
                                          state: job,
                                        });
                                      }}
                                    >
                                      Edit
                                    </button>
                                  )}
                                </Menu.Item>
                                <Menu.Item>
                                  {({ active }) => (
                                    <button
                                      className={classNames(
                                        active
                                          ? "bg-gray-100 text-gray-900"
                                          : "text-gray-700",
                                        "block px-4 py-2 text-sm text-center"
                                      )}
                                      onClick={() => {
                                        _showComments(job.id)
                                      }}
                                    >
                                      Comments
                                    </button>
                                  )}
                                </Menu.Item>

                                <Menu.Item>
                                  {({ active }) => (
                                    <button
                                      className="hover:text-white hover:bg-red-900 block px-4 py-2 text-sm"
                                      onClick={() => {
                                        dispatch(setModalData({ id: job.id }));
                                        dispatch(setModalOpen(true));
                                      }}
                                    >
                                      Delete
                                    </button>
                                  )}
                                </Menu.Item>
                              </div>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          {/* End of user table */}
          {open ? <Comments
            key={jobId}
            jobId={jobId}
            open={open}
            setOpen={setOpen}
            title={"Comments"}
          /> : null}
        </div>
      </div>
    </>
  );
}
