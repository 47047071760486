import { Outlet } from "react-router-dom";
import Notification from "../../components/ui/alerts/Notification";
import { useSelector } from "react-redux";

export default function UserLayout() {
  const notification = useSelector((state) => state.notification);

  return (
    <div className="w-full">
      <Notification
        type={notification.type}
        message={notification.message}
        title={notification.title}
        status={notification.status}
      />
      <Outlet />
    </div>
  );
}