import { useLocation } from "react-router-dom";
import { ChevronRightIcon } from '@heroicons/react/solid'

export default function Breadcrumb() {
  const location = useLocation();
  let pathChunks = location.pathname.split("/");
  pathChunks = pathChunks.filter(n => n);
  var crumbElements = [];
  let breadcrumbSeparator = <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-500" aria-hidden="true" />;
  pathChunks.forEach((path, index) => {
    crumbElements.push(<li key={path}>
      <div className="flex items-center">
        {index !== 0 ? breadcrumbSeparator : null}
        {/* <a href="/" className="text-gray-400 hover:text-gray-500"> */}
        <a href="/" className="text-sm font-medium text-gray-400 hover:text-gray-200">
          {path.charAt(0).toUpperCase() + path.slice(1)}
        </a>
        {/* </a> */}
      </div>
    </li>)
  })
  return (
    <div >
      <nav aria-label="Breadcrumb">
        <ol className="flex items-center space-x-4">

          {crumbElements}
        </ol>
      </nav>
    </div>
  );

}